export default defineI18nConfig(() => ({
    fallbackLocale: 'de',
    datetimeFormats: {
        'de': {
            dateShort: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            dateLong: {
                year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
            }
        },
        'en': {
            dateShort: {
                year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute:'numeric',  hour12: true
            },
        }
    }
}))